exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-bpir-index-js": () => import("./../../../src/pages/bpir/index.js" /* webpackChunkName: "component---src-pages-bpir-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-tapes-construction-index-js": () => import("./../../../src/pages/tapes/construction/index.js" /* webpackChunkName: "component---src-pages-tapes-construction-index-js" */),
  "component---src-pages-tapes-double-sided-filmic-index-js": () => import("./../../../src/pages/tapes/double-sided/filmic/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-filmic-index-js" */),
  "component---src-pages-tapes-double-sided-foam-index-js": () => import("./../../../src/pages/tapes/double-sided/foam/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-foam-index-js" */),
  "component---src-pages-tapes-double-sided-index-js": () => import("./../../../src/pages/tapes/double-sided/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-index-js" */),
  "component---src-pages-tapes-double-sided-other-index-js": () => import("./../../../src/pages/tapes/double-sided/other/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-other-index-js" */),
  "component---src-pages-tapes-double-sided-tissue-index-js": () => import("./../../../src/pages/tapes/double-sided/tissue/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-tissue-index-js" */),
  "component---src-pages-tapes-double-sided-transfer-adhesive-index-js": () => import("./../../../src/pages/tapes/double-sided/transfer-adhesive/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-transfer-adhesive-index-js" */),
  "component---src-pages-tapes-double-sided-uhb-index-js": () => import("./../../../src/pages/tapes/double-sided/uhb/index.js" /* webpackChunkName: "component---src-pages-tapes-double-sided-uhb-index-js" */),
  "component---src-pages-tapes-foam-index-js": () => import("./../../../src/pages/tapes/foam/index.js" /* webpackChunkName: "component---src-pages-tapes-foam-index-js" */),
  "component---src-pages-tapes-index-js": () => import("./../../../src/pages/tapes/index.js" /* webpackChunkName: "component---src-pages-tapes-index-js" */),
  "component---src-pages-tapes-industrial-index-js": () => import("./../../../src/pages/tapes/industrial/index.js" /* webpackChunkName: "component---src-pages-tapes-industrial-index-js" */),
  "component---src-pages-tapes-single-sided-index-js": () => import("./../../../src/pages/tapes/single-sided/index.js" /* webpackChunkName: "component---src-pages-tapes-single-sided-index-js" */),
  "component---src-pages-tapes-specialty-index-js": () => import("./../../../src/pages/tapes/specialty/index.js" /* webpackChunkName: "component---src-pages-tapes-specialty-index-js" */),
  "component---src-pages-tapes-surface-protection-index-js": () => import("./../../../src/pages/tapes/surface-protection/index.js" /* webpackChunkName: "component---src-pages-tapes-surface-protection-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-templates-about-entry-js": () => import("./../../../src/templates/about_entry.js" /* webpackChunkName: "component---src-templates-about-entry-js" */),
  "component---src-templates-product-cloth-js": () => import("./../../../src/templates/product_cloth.js" /* webpackChunkName: "component---src-templates-product-cloth-js" */),
  "component---src-templates-product-construction-js": () => import("./../../../src/templates/product_construction.js" /* webpackChunkName: "component---src-templates-product-construction-js" */),
  "component---src-templates-product-double-sided-filmic-tapes-js": () => import("./../../../src/templates/product_double_sided_filmic_tapes.js" /* webpackChunkName: "component---src-templates-product-double-sided-filmic-tapes-js" */),
  "component---src-templates-product-double-sided-foam-tapes-js": () => import("./../../../src/templates/product_double_sided_foam_tapes.js" /* webpackChunkName: "component---src-templates-product-double-sided-foam-tapes-js" */),
  "component---src-templates-product-double-sided-js": () => import("./../../../src/templates/product_double_sided.js" /* webpackChunkName: "component---src-templates-product-double-sided-js" */),
  "component---src-templates-product-double-sided-tissue-tapes-js": () => import("./../../../src/templates/product_double_sided_tissue_tapes.js" /* webpackChunkName: "component---src-templates-product-double-sided-tissue-tapes-js" */),
  "component---src-templates-product-double-sided-transfer-adhesive-tapes-js": () => import("./../../../src/templates/product_double_sided_transfer_adhesive_tapes.js" /* webpackChunkName: "component---src-templates-product-double-sided-transfer-adhesive-tapes-js" */),
  "component---src-templates-product-double-sided-uhb-tapes-js": () => import("./../../../src/templates/product_double_sided_uhb_tapes.js" /* webpackChunkName: "component---src-templates-product-double-sided-uhb-tapes-js" */),
  "component---src-templates-product-foam-js": () => import("./../../../src/templates/product_foam.js" /* webpackChunkName: "component---src-templates-product-foam-js" */),
  "component---src-templates-product-foil-js": () => import("./../../../src/templates/product_foil.js" /* webpackChunkName: "component---src-templates-product-foil-js" */),
  "component---src-templates-product-specialty-js": () => import("./../../../src/templates/product_specialty.js" /* webpackChunkName: "component---src-templates-product-specialty-js" */),
  "component---src-templates-product-surface-protection-js": () => import("./../../../src/templates/product_surface_protection.js" /* webpackChunkName: "component---src-templates-product-surface-protection-js" */),
  "component---src-templates-services-entry-js": () => import("./../../../src/templates/services_entry.js" /* webpackChunkName: "component---src-templates-services-entry-js" */)
}

